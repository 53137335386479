#create-blueprint {
  .blueprint-material-amount-wrapper,
  .blueprint-icon-wrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    margin: 8px 0;

    .delete-icon {
      cursor: pointer;
      margin-top: 12px;
      width: 24px;
      height: 24px;
    }

    img {
      max-width: 60%;
      margin-top: 12px;
    }
  }
}

#create-qualification {
  .qualification-display {
    &__header {
      margin: 6px;
    }

    &__container {
      display: flex;
      gap: 12px;
      padding-bottom: 8px;
    }
  }
}

.address-form-component {
  &__coordinates {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
  }
}
